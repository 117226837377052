<template>
  <div>
    <div class="score-header">
      <div class="menu">
        <div @click="changeType('score')" class="menu-item" :class="{ active: routerName === 'score' }">
          {{ $t("liveScore") }}
        </div>
        <div @click="changeType('completed')" class="menu-item" :class="{ active: routerName === 'completed' }">
          {{ $t("FinishingScore") }}
        </div>
        <div @click="changeType('schedule')" class="menu-item" :class="{ active: routerName === 'schedule' }">
          {{ $t("FutureSchedule") }}
        </div>
        <div @click="changeType('collect')" class="menu-item" :class="{ active: routerName === 'collect' }">
          {{ $t("myCollection") }}
        </div>
      </div>
      <div class="tool">
        <div class="filter tool-item">
          {{ $t("filter") }}：
          <a-popover trigger="click" v-model="filterShow">
            <template slot="content">

              <div class="gaming-filter-wrap">
                <div class="filter-wrap">
                  <p>{{ $t("eventFilter") }}</p>
                  <div class="level-filter" v-if="currentType === 'football'">
                    <button @click="selectAll"> {{ $t("AllLeagues") }}</button>
                    <button @click="changeLevel(5)">{{ $t("FiveMajorLeagues") }}</button>
                    <button @click="changeLevel(1)">{{ $t("firstLeague") }}</button>
                  </div>
                  <div class="filter-content">
                    <!-- <div class="filter-item">
                      <div class="label">热门赛事</div>
                      <ul class="league-list">
                        <li class="league-item" v-for="(hot, idx) in league.hot" :class="{ 'checked': checkedLeague.includes(hot.league_id) }" :key="`hot-${hot.league_id}-${idx}`">
                          <div class="checkbox">
                            <input type="checkbox" :id="`hot-league-${hot.league_id}`" :checked="checkedLeague.includes(hot.league_id)" @change="changeLeagueSelect($event, hot)">
                            <label :class="{ 'checked': checkedLeague.includes(hot.league_id) }" :for="`league-${hot.league_id}`"></label>
                          </div>
                          <label class="name" :for="`hot-league-${hot.league_id}`">
                            <span>{{ hot.big_short }}</span>
                            <span class="num">[{{ hot.num }}]</span>
                          </label>
                        </li>
                      </ul>
                    </div> -->
                    <div class="filter-item" v-for="(leagueList, key) in league.list" :key="`${key}`">
                      <div class="label">{{ key }}</div>
                      <ul class="league-list">
                        <li class="league-item" v-for="(hot, idx) in leagueList" :class="{
                          checked: checkedLeague.includes(hot.league_id),
                          basketball: currentType == 'basketball',
                        }" :key="`${key}-${hot.league_id}-${idx}`">
                          <div class="checkbox">
                            <input type="checkbox" :id="`league-${hot.league_id}`"
                              :checked="checkedLeague.includes(hot.league_id)"
                              @change="changeLeagueSelect($event, hot)" />
                            <label :class="{
                              checked: checkedLeague.includes(hot.league_id),
                            }" :for="`league-${hot.league_id}`"></label>
                          </div>
                          <label class="name" :for="`league-${hot.league_id}`">
                            <span class="league-name" v-if="scoreLanguage === 'zh_TW'">{{ hot.big_short }}</span>
                            <span class="league-name" v-else-if="scoreLanguage === 'zh_CN'">{{ hot.gb_short }}</span>
                            <span class="league-name" v-else>{{ hot.en_short }}</span>
                            <span class="num">[{{ hot.num }}]</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="left">
                      <button @click="selectAll">{{$t('selectAll')}}</button>
                      <button @click="reverseSelect">{{$t('counterElection')}}</button>
                    </div>
                    <div class="right">
                      <button class="cancel" @click="filterCancel">{{$t('cancel')}}</button>
                      <button @click="filterSubmit">{{$t('sure')}}</button>
                    </div>
                  </div>
                </div>
              </div>

            </template>
            <button class="filter-button">
              {{ $t('eventFilter')}}<a-icon type="down" />
            </button>
          </a-popover>
        </div>
        <!-- <div class="sound tool-item">
          声音：静音
        </div> -->
        <div class="setting tool-item">
          <a-popover trigger="click">
            <template slot="content">
              <div class="setting-filter-wrap">
                <div class="filter-wrap">
                  <p>{{$t('functionSelection')}}</p>
                  <div class="filter-body">
                    <!-- <div class="filter-item">
                      <p>语言切换</p>
                      <a-radio-group fill="#FFCD00" v-model="language" class="filter-language" @change="setScoreLanguage">
                        <a-radio class="user-radio" value="zh_CN">简体</a-radio>
                        <a-radio class="user-radio" value="zh_TW">繁体</a-radio>
                        <a-radio class="user-radio" value="en_US" v-if="currentType == 'football'">皇冠</a-radio>
                      </a-radio-group>
                    </div> -->
                    <div class="filter-item" v-if="currentType == 'football'">
                      <p>{{$t('redAndYellow')}}</p>
                      <a-radio-group fill="#FFCD00" v-model="cardShow" class="filter-language"
                        @change="changeScoreCardShow">
                        <a-radio class="user-radio" :value="true">{{$t('show')}}</a-radio>
                        <a-radio class="user-radio" :value="false">{{$t('hide')}}</a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <button class="setting-button">
              <a-icon type="setting" />{{$t('functionSelection')}}
            </button>
          </a-popover>
        </div>
      </div>
    </div>
    <div class="header-date" v-if="['completed', 'schedule'].includes(routerName)">
      <div class="date-btn">
        <div class="date-btn-txt">
          <!-- <img src="../../assets/images/gaming/date-icon.png" alt=""> -->
          <span>日历</span>
        </div>
        <el-date-picker v-model="activeDate" type="date" placeholder="" value-format="yyyy/MM/dd" :editable="false"
          popper-class="picker-date" :pickerOptions="pickerOptions" @change="switchDate">
        </el-date-picker>
      </div>
      <div class="date-list">
        <div class="date-item" v-for="(date, idx) in formDate" :key="`${date}-${idx}`"
          :class="{ active: activeDate === date }" @click="switchDate(date)">
          <p>{{ date | formateDate }}</p>
          <p>{{ date | formateWeek }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

export default {
  name: "score_header",
  data() {
    return {
      settingShow: false,
      filterShow: false,
      pickerDate: "",
      activeDate: "",
      league: {
        data: {},
        list: [],
      },
      checkedLeague: [],
      language: "zh_CN",
      cardShow: true,
    };
  },
  filters: {
    formateDate(val) {
      const str = dayjs(new Date()).format("YYYY/MM/DD");
      return val === str ? "今天" : dayjs(val).format("MM/DD");
    },
    formateWeek(val) {
      return dayjs(val).format("dddd");
    },
  },
  computed: {
    ...mapState({
      scoreLanguage: "scoreLanguage",
    }),
    currentType() {
      return (this.$route.params.type || "football").toLowerCase();
    },
    // selectedNum () {
    //   const selectedArr = (this.league.list || []).filter(item => this.checkedLeague.includes(item.league_id))
    //   const total = selectedArr.reduce((prep,curr,idx,arr) => {
    //     return prep + curr.num
    //   }, 0)
    //   return total
    // },
    routerName() {
      return this.$route.name;
    },
    formDate() {
      let date = [];
      if (this.routerName === "completed") {
        date = [...Array(7).keys()]
          .map((days) => {
            let t = new Date(Date.now() - 86400000 * days);
            let str = dayjs(t).format("YYYY/MM/DD");
            return str;
          })
          .reverse();
      } else if (this.routerName === "schedule") {
        date = [...Array(7).keys()].map((days) => {
          let t = new Date(Date.now() + 86400000 * days);
          // `${t.getFullYear()}/${t.getMonth() + 1}/${t.getDate()}`
          let str = dayjs(t).format("YYYY/MM/DD");
          return str;
        });
      }
      return date;
    },
    pickerOptions() {
      if (this.routerName === "completed") {
        return {
          disabledDate(time) {
            let today = new Date(dayjs().format("YYYY/MM/DD")).getTime();
            let one = 30 * 24 * 3600 * 1000;
            let oneMonths = today - one;
            return time.getTime() > today || time.getTime() < oneMonths;
          },
        };
      }
      if (this.routerName === "schedule") {
        return {
          disabledDate(time) {
            let today = new Date(dayjs().format("YYYY/MM/DD")).getTime();
            let one = 30 * 24 * 3600 * 1000;
            let oneMonths = today + one;
            return time.getTime() < today || time.getTime() > oneMonths;
          },
        };
      }
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.params.type !== from.params.type ||
        to.query.day !== from.query.day ||
        (to.name !== from.name && to.name !== "collect")
      ) {
        if (to.name !== from.name && to.name !== "collect") {
          this.activeDate = dayjs(new Date()).format("YYYY/MM/DD");
        }
        this.getLeagueList();
      }
    },
  },
  methods: {
    ...mapActions({
      setScoreLanguage: "setScoreLanguage",
      changeScoreCardShow: "changeScoreCardShow",
    }),
    getLeagueList() {
      let day = this.$route.query.day;
      if (["completed", "schedule"].includes(this.$route.name)) {
        day = this.$route.query.day
          ? dayjs(this.$route.query.day).format("YYYY-MM-DD")
          : dayjs(new Date()).format("YYYY-MM-DD");
      }
      let path = "football_score_league";
      if (this.currentType === "basketball") {
        path = "basketball_score_league";
      }
      let query = ``;
      if (day) {
        query = `?day=${day}`;
      }
      this.$api.get(`/web/${path}${query}`).then((res) => {
        if (res.code === 200) {
          this.league = res.data;
          this.selectAll(true, "emit");
        }
      });
    },
    // 全选
    selectAll(isAll, type) {
      const selectedArr = [];
      if (isAll) {
        for (let key in this.league.list) {
          this.league.list[key].forEach((league) => {
            selectedArr.push(league.league_id);
          });
        }
      }
      this.checkedLeague = selectedArr;
      if (type === "emit") {
        this.$emit("update", this.checkedLeague);
      }
    },
    // 反选
    reverseSelect() {
      const reverseList = [];
      for (let key in this.league.list) {
        this.league.list[key].forEach((league) => {
          if (!this.checkedLeague.includes(league.league_id)) {
            reverseList.push(league.league_id);
          }
        });
      }
      this.checkedLeague = reverseList;
    },
    changeLeagueSelect(e, league) {
      const selectedTmp = Array.from(new Set(this.checkedLeague));
      const isCheck = e.target.checked;
      if (isCheck) {
        selectedTmp.push(league.league_id);
      } else {
        const idx = selectedTmp.indexOf(league.league_id);
        if (idx > -1)
          selectedTmp.splice(selectedTmp.indexOf(league.league_id), 1);
      }
      this.checkedLeague = Array.from(new Set(selectedTmp));
    },
    changeLevel(level) {
      const selectedArr = [];
      for (let key in this.league.list) {
        this.league.list[key].forEach((league) => {
          if (league.level === level) selectedArr.push(league.league_id);
        });
      }
      // 有需要可以加提示，不要继续筛选出空列表
      if (selectedArr.length < 1 && level === 5) {
        console.log("当前无五大联赛");
      }
      this.checkedLeague = selectedArr;
    },
    filterCancel() {
      this.filterShow = false;
    },
    filterSubmit() {
      this.$emit("update", this.checkedLeague);
      this.filterShow = false;
    },
    changeType(type) {
      if (this.$route.name !== type) {
        const query = {};
        if (
          this.$route.query &&
          this.$route.query.day &&
          !["score", "collect"].includes(type)
        ) {
          query.day = this.$route.query.day;
        }
        if (type == "schedule") {
          query.day = dayjs()
            // .add(1, "day")
            .format("YYYYMMDD");
        } else if (type == "completed") {
          query.day = dayjs()
            // .subtract(1, "day")
            .format("YYYYMMDD");
        }
        this.$router.push({
          name: type,
          params: {
            type: this.$route.params.type,
          },
          query,
        });
      }
    },
    switchDate(date) {
      this.activeDate = date;
      this.$router.push({
        name: this.$route.name,
        params: {
          type: this.$route.params.type,
        },
        query: {
          day: date.replaceAll("/", ""),
        },
      });
    },
  },
  mounted() {
    this.getLeagueList();
    if (this.$route.query && this.$route.query.day) {
      this.activeDate = dayjs(this.$route.query.day, "YYYYMMDD").format(
        "YYYY/MM/DD"
      );
    } else {
      this.activeDate = dayjs(new Date()).format("YYYY/MM/DD");
    }
  },
};
</script>
<style lang="less">
@import url('~@/assets/less/variables.less');

.gaming-filter-wrap {
  z-index: 9 !important;

  .filter-wrap {
    p {
      font-size: 16px;
      line-height: 22px;
      color: #000;
    }

    .level-filter {
      padding: 15px 0 10px;

      button {
        background: none;
        border: none;
        outline: none;
        color: #666;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: #ffcd00;
        }
      }
    }

    .filter-content {
      height: 260px;
      overflow-y: auto;
      padding: 10px;

      .filter-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .label {
          padding: 6px 0 0;
        }
      }

      .league-list {
        display: flex;
        flex-wrap: wrap;
        width: 438px;
        gap: 10px 20px;

        .league-item {
          &.basketball {
            width: 45%;

            .name {
              .league-name {
                max-width: 130px;
              }
            }
          }

          width: 30%;
          display: flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;
          background: #f7f7f8;
          border-radius: 4px;
          margin-bottom: 4px;
          color: #999;

          // &:hover {
          //   .name {
          //     color: #FFCD00;
          //     .num {
          //       color: #FFCD00;
          //     }
          //   }
          // }
          &.checked {
            background: #ffcd00;
            color: #000;
          }

          .checkbox {
            width: 20px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-around;
            display: none;

            input {
              display: none;
            }

            label {
              display: block;
              width: 12px;
              height: 12px;
              cursor: pointer;
              position: relative;

              &::after {
                content: " ";
                position: absolute;
                width: 16px;
                height: 16px;
                border: 1px solid #939393;
                top: -2px;
                left: -2px;
              }

              &.checked {
                &::after {
                  border: 1px solid #db4a48;
                }

                &::before {
                  content: "\2713";
                  color: #db4a48;
                  font-size: 14px;
                }
              }
            }
          }

          .name {
            display: flex;
            width: 100%;
            height: 100%;
            padding: 7px 10px;
            cursor: pointer;

            .league-name {
              max-width: 75px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }

    .bottom {
      height: 35px;
      padding: 5px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        gap: 10px;

        button {
          border: none;
          background-color: transparent;
          outline: none;
          font-size: 14px;
          height: 30px;
          padding: 0 10px;
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        gap: 10px;
        align-items: center;

        button {
          border-radius: 5px;
          height: 30px;
          border: none;
          background: #ffcd00;
          color: #fff;
          font-size: 14px;
          cursor: pointer;

          &.cancel {
            background: #f7f7f8;
            color: #666;
          }
        }
      }
    }
  }
}

.setting-filter-wrap {
  width: 280px;

  .filter-wrap {
    >p {
      color: #000;
      font-size: 16px;
    }
  }

  .filter-body {
    display: flex;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 16px;
    margin-top: 10px;

    .filter-item {
      flex: 1;
      font-size: 12px;
      color: #666;

      >p {
        margin-bottom: 5px;
      }
    }

    .filter-language {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 12px;
    }

    .user-radio {
      .el-radio__input.is-checked+.el-radio__label {
        color: rgba(0, 0, 0, 0.9);
      }

      .el-radio__input.is-checked .el-radio__inner {
        border-color: #ffcd00;
        background: #ffcd00;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import url('~@/assets/less/variables.less');

.score-header {
  background: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;

  .menu {
    display: flex;

    .menu-item {
      // min-width: 110px;
      padding:0 20px;
      line-height: 50px;
      text-align: center;
      color: #262a50;
      font-size: 16px;
      cursor: pointer;

      &.active {
        background: @primary-color;
        color: #ffffff;
      }
    }
  }

  .tool {
    display: flex;
    line-height: 50px;
    padding: 0 10px;

    .tool-item {
      padding: 0 8px;
      display: flex;
      align-items: center;

      &.setting {
        i {
          font-size: 22px;
          margin-right: 5px;
        }
      }
    }
  }

  .filter-button {
    cursor: pointer;
    height: 27px;
    line-height: 27px;
    background: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
    padding: 0px 8px;
  }

  .setting-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 3px 8px;
  }
}

.header-date {
  display: flex;
  gap: 23px;
  margin-top: 20px;

  .date-btn {
    width: 147px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 62px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .date-btn-txt {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      img {
        width: 18px;
      }
    }

    /deep/ .el-date-editor.el-input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }

    /deep/ .el-input--prefix .el-input__inner {
      opacity: 0;
      height: 100%;
      cursor: pointer;
    }

    /deep/ .el-input__icon {
      opacity: 0;
    }
  }

  .date-list {
    display: flex;
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;

    .date-item {
      flex: 1;
      height: 62px;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      font-size: 14px;
      background: #fff;
      border-right: 1px solid #e0e0e0;
      cursor: pointer;

      &:last-child {
        border: none;
      }

      p {
        margin: 0;
      }

      &.active {
        background: #ffcd00;
        color: #fff;
      }
    }
  }
}
</style>
