<template>
  <div class="basketball-item-wrap">
    <div class="item-title">
      <div class="time">{{ startTime }}</div>
      <div class="statues" :class="{ 'going':  [1,2,3,4,5,6,7,50].includes(info.state), 'end': info.state < 0 }">
        {{ stateZh }} {{ [1,2,3,4,5,6,7].includes(info.state) ? info.surplus_time : ''}}
      </div>
      <div class="score">
        <!-- <template v-if="info.league_id == 8">
          <div class="up-half">
            上半场
          </div>
          <div class="up-half">
            下半场
          </div>
        </template> -->
        <template>
          <div class="one">1</div>
          <div class="two">2</div>
          <div class="three">3</div>
          <div class="four">4</div>
        </template>
        <div class="ot" v-if="info.away_overtime_one_score">OT</div>
      </div>
      <div class="half-score">{{$t('upper')}}/{{$t('lower')}}</div>
      <div class="all-score">{{$t('fullTime')}}</div>
      <div class="diff-score">{{$t('PointsDifference')}}</div>
      <div class="total-score">{{$t('totalScore')}}</div>
      <div class="success">{{$t('victoryOrDefeat')}}</div>
      <div class="odd">{{$t('handicap')}}</div>
      <div class="all-odd">{{$t('handicap')}}</div>
      <div class="play">{{$t('operate')}}</div>
    </div>
    <div class="item-body">
      <div class="league" :style="{'color': info.color}">
        <template v-if="scoreLanguage === 'zh_TW'">
          {{info.league_name_zht ? info.league_name_zht : info.league_name_zh}}
        </template>
        <template v-else-if="scoreLanguage === 'en_US'">
          {{info.league_name_sb ? info.league_name_sb : info.league_name_zh}}
        </template>
        <template v-else>
          {{info.league_name_zh}}
        </template>
      </div>
      <div class="statues">
        
          <div class="top">
            <a :href="`/detail/2/${info.tournament_id}`" target="_blank">
              <template v-if="scoreLanguage === 'zh_TW'">
                {{info.home_namezht ? info.home_name_zht : info.home_name_zh}}
              </template>
              <template v-else-if="scoreLanguage === 'en_US'">
                {{info.home_name_sb ? info.home_name_sb : info.home_name_zh}}
              </template>
              <template v-else>
                {{info.home_team_zh}}
              </template>
            </a>
          </div>
        <div class="down">
          <a :href="`/detail/2/${info.tournament_id}`" target="_blank">
            <template v-if="scoreLanguage === 'zh_TW'">
              {{info.away_name_zht ? info.away_name_zht : info.away_name_zh}}
            </template>
            <template v-else-if="scoreLanguage === 'en_US'">
              {{info.away_name_sb ? info.away_name_sb : info.away_name_zh}}
            </template>
            <template v-else>
              {{info.away_team_zh}}
            </template>
          </a>
        </div>
      </div>
      <div class="score">
        <!-- <template v-if="info.league_id == 8">
          <div class="up-half">
            <div class="up">
              {{ info.home_one_score ? info.home_one_score : '-'}}
            </div>
            <div class="down">
              {{ info.home_three_score ? info.home_three_score : '-'}}
            </div>
          </div>
          <div class="up-half">
            <div class="up">
              {{ info.away_one_score ? info.away_one_score : '-'}}
            </div>
            <div class="down">
              {{ info.away_three_score ? info.away_three_score : '-'}}
            </div>
          </div>
        </template> -->
        <template>
          <div class="one">
            <div class="up">
              {{ info.home_one_score ? info.home_one_score : '-'}}
            </div>
            <div class="down">
              {{ info.away_one_score ? info.away_one_score : '-'}}
            </div>
          </div>
          <div class="two">
            <div class="up">
              {{ info.home_two_score ? info.home_two_score : '-'}}
            </div>
            <div class="down">
              {{ info.away_two_score ? info.away_two_score : '-'}}
            </div>
          </div>
          <div class="three">
            <div class="up">
              {{ info.home_three_score ? info.home_three_score : '-'}}
            </div>
            <div class="down">
              {{ info.away_three_score ? info.away_three_score : '-'}}
            </div>
          </div>
          <div class="four">
            <div class="up">
              {{ info.home_four_score ? info.home_four_score : '-'}}
            </div>
            <div class="down">
              {{ info.away_four_score ? info.away_four_score : '-'}}
            </div>
          </div>
        </template>
        <div class="ot" v-if="info.away_overtime_one_score">
          <div class="up">
            {{ homeOt }}
          </div>
          <div class="down">
            {{ awayOt }}
          </div>
        </div>
      </div>
      <div class="half-score">
        <div class="up">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? `${homeUpHalf}/${homeDownHalf ? homeDownHalf : '-'}` : '-/-'}}
        </div>
        <div class="down">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? `${awayUpHalf}/${awayDownHalf ? awayDownHalf : '-'}` : '-/-'}}
        </div>
      </div>
      <div class="all-score">
        <div class="up">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? homeTotal : '-'}}
        </div>
        <div class="down">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? awayTotal : '-' }}
        </div>
      </div>
      <div class="diff-score">
        <div class="up">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? `${$t('totalScore')}: ${homeTotal - awayTotal}` : '' }}
        </div>
        <div class="down">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? `${$t('totalScore')}: ${homeUpHalf - awayUpHalf}` : '' }}
        </div>
      </div>
      <div class="total-score">
        <div class="up">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? `${$t('half')}: ${homeTotal + awayTotal}` : '' }}
        </div>
        <div class="down">
          {{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? `${$t('half')}: ${homeUpHalf + awayUpHalf}` : '' }}
        </div>
      </div>
      <div class="success">
        <div class="up">{{figure.t_a}}</div>
        <div class="down">{{figure.b_a}}</div>
      </div>
      <div class="odd">
        <div class="up">
          <span class="active">{{figure.t_b}}</span>
          <span>{{figure.t_c}}</span>
        </div>
        <div class="down">
          <span class="active">{{figure.b_b}}</span>
          <span>{{figure.b_c}}</span>
        </div>
      </div>
      <div class="all-odd">
        <div class="up">
          <span class="active">{{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? (figure.t_d ? `小${figure.t_d}` : '') : (figure.t_d ? `大${figure.t_d}` : '') }}</span>
          <span>{{  figure.t_e }}</span>
        </div>
        <div class="down">
          <span class="active">{{ [1,2,3,4,5,6,7,50,-1].includes(info.state) ? (figure.b_d ? `大${figure.b_d}` : '') : (figure.b_d ? `小${figure.b_d}` : '') }}</span>
          <span>{{ figure.b_e }}</span>
        </div>
      </div>
      <div class="play">
        <div class="top">
          <a :href="`/detail/2/${info.tournament_id}/eu`" target="_blank">
            <span>析</span>
          </a>
          <template v-if="routerName != 'completed'">
            <img v-if="isCollect" @click="collect" src="@/assets/images/score/collect-active.png" alt="">
            <img v-else @click="collect" src="@/assets/images/score/collect.png" alt="">
          </template>
        </div>
        <div class="down" v-if="routerName != 'completed'">
          <img src="@/assets/images/score/voice.png" alt=""  @click="getAnchor" v-if="info.is_zb">
          <a :href="`/detail/2/${info.tournament_id}`" target="_blank" v-else >
            <img src="@/assets/images/score/live.png" alt=""/>
          </a>
        
          <template v-if="routerName != 'collect' && routerName != 'schedule'">
            <img v-if="isTop" @click="top" src="@/assets/images/score/down.png" alt="">
            <img v-else @click="top" src="@/assets/images/score/top.png" alt="">
          </template>
        </div>
      </div>
    </div>
    <div class="item-remark" v-if="info.remark">
      {{ info.remark }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs'

export default {
  name: 'basketball_item',
  props: {
    info: Object,
    collectIds: Array
  },
  data () {
    return {
      localTopIds: [],
      state: {
        0: "Chưa bắt đầu",
        1: "Phần 1",
        2: "Phần 2",
        3: "Phần 3",
        4: "Phần 4",
        5: "Tăng ca",
        6: "Tăng ca",
        7: "Tăng ca",
        "-1": "Hoàn thành",
        "-2": "không chắc",
        "-3": "ngắt",
        "-4": "Hủy bỏ",
        "-5": "hoãn",
        50: "tiền vệ",
      },
      stateNcaa: {
        0: "Chưa bắt đầu",
        1: "nửa đầu",
        3: "một nửa thứ hai",
        5: "Tăng ca",
        6: "Tăng ca",
        7: "Tăng ca",
        "-1": "Hoàn thành",
        "-2": "không chắc",
        "-3": "ngắt",
        "-4": "Hủy bỏ",
        "-5": "hoãn",
        50: "tiền vệ",
      },
      bClass: '',
      cClass: '',
      eClass: '',
      fClass: '',
    }
  },
  computed: {
    ...mapState({
      scoreLanguage: 'scoreLanguage',
      scoreCardShow: 'scoreCardShow'
    }),
    routerName () {
      return this.$route.name
    },
    isTop () {
      return this.localTopIds.indexOf(this.info.tournament_id) > -1
    },
    isCollect () {
      return this.collectIds.indexOf(this.info.tournament_id) > -1
    },
    startTime () {
      return dayjs(this.info.match_time_int * 1000).format('MM/DD HH:mm')
    },
    stateZh () {
      return this.info.league_id == 8 ? this.stateNcaa[this.info.state] : this.state[this.info.state]
    },
    // 主 总加时
    homeOt () {
      return Number(this.info.home_overtime_one_score) + Number(this.info.home_overtime_two_score) + Number(this.info.home_overtime_three_score)
    },
    // 客 总加时
    awayOt () {
      return Number(this.info.away_overtime_one_score) + Number(this.info.away_overtime_two_score) + Number(this.info.away_overtime_three_score)
    },
    // 主 上半
    homeUpHalf () {
      if (this.info.league_id == 8) {
        return Number(this.info.home_one_score)
      } else {
        return Number(this.info.home_one_score) + Number(this.info.home_two_score)
      }
    },
    // 主 下半
    homeDownHalf (){
      if (this.info.league_id == 8) {
        return Number(this.info.home_three_score)
      } else {
        return Number(this.info.home_three_score) + Number(this.info.home_four_score)
      }
    },
    // 客 上半
    awayUpHalf () {
      if (this.info.league_id == 8) {
        return Number(this.info.away_one_score)
      } else {
        return Number(this.info.away_one_score) + Number(this.info.away_two_score)
      }
    },
    // 客 下半
    awayDownHalf (){
      if (this.info.league_id == 8) {
        return Number(this.info.away_three_score)
      } else {
        return Number(this.info.away_three_score) + Number(this.info.away_four_score)
      }
    },
    // 主 全场
    homeTotal () {
      return Number(this.info.home_one_score) + Number(this.info.home_two_score) + Number(this.info.home_three_score)
        + Number(this.info.home_four_score) + Number(this.info.home_overtime_one_score)
        + Number(this.info.home_overtime_two_score) + Number(this.info.home_overtime_three_score)
    },
    // 客 全场
    awayTotal () {
      return Number(this.info.away_one_score) + Number(this.info.away_two_score) + Number(this.info.away_three_score)
        + Number(this.info.away_four_score) + Number(this.info.away_overtime_one_score)
        + Number(this.info.away_overtime_two_score) + Number(this.info.away_overtime_three_score)
    },
    figure () {
      const figureInfo = {
        // 主指数
        t_a: '',
        t_b: '',
        t_c: '',
        t_d: '',
        t_e: '',
        // 客指数
        b_a: '',
        b_b: '',
        b_c: '',
        b_d: '',
        b_e: '',
      }
      if (this.info && ((this.info.c && typeof this.info.c === "string") || (this.info.b && typeof this.info.b === "string") || (this.info.a && typeof this.info.a === "string"))) {
        let figure = {
          1: ["", "", ""],
          2: ["", "", ""],
          3: ["", "", ""]
        }
        if (this.info.c && typeof this.info.c === "string") {
          figure = JSON.parse(this.info.c)
        } else if (this.info.b && typeof this.info.b === "string") {
          figure = JSON.parse(this.info.b)
        } else if (this.info.a && typeof this.info.a === "string") {
          figure = JSON.parse(this.info.a)
        }
        if ((this.info.c && typeof this.info.c === "string") || (this.info.b && typeof this.info.b === "string") || (this.info.a && typeof this.info.a === "string")) {
          if (figure['1'] && figure['1'].length > 0) {
            figureInfo.b_b = figure['1']['0'] < 0 ? Math.abs(figure['1']['0']) : ""
            figureInfo.b_c = figure['1']['2']
            figureInfo.t_b = figure['1']['0'] < 0 ? "" : figure['1']['0']
            figureInfo.t_c = figure['1']['1']
          }
          if (figure['2'] && figure['2'].length > 0) {
            figureInfo.b_a = figure['2']['1']
            figureInfo.t_a = figure['2']['0']
          }
          if (figure['3'] && figure['3'].length > 0) {
            figureInfo.b_d = figure['3']['0']
            figureInfo.b_e = figure['3']['2']
            figureInfo.t_d = figure['3']['0']
            figureInfo.t_e = figure['3']['1']
          }
        }
      }
      return figureInfo
    }
  },
  watch: {
    'figure.b': {
      handler (nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.bClass = 'up'
        }
        if (nVal && oVal && nVal < oVal) {
          this.bClass = 'down'
        }
        const timer = setTimeout(() => {
          this.bClass = ''
          clearTimeout(timer)
        }, 3000)
      }
    },
    'figure.c': {
      handler (nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.cClass = 'up'
        }
        if (nVal && oVal && nVal < oVal) {
          this.cClass = 'down'
        }
        const timer = setTimeout(() => {
          this.cClass = ''
          clearTimeout(timer)
        }, 3000)
      }
    },
    'figure.e': {
      handler (nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.eClass = 'up'
        }
        if (nVal && oVal && nVal < oVal) {
          this.eClass = 'down'
        }
        const timer = setTimeout(() => {
          this.eClass = ''
          clearTimeout(timer)
        }, 3000)
      }
    },
    'figure.f': {
      handler (nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.fClass = 'up'
        }
        if (nVal && oVal && nVal < oVal) {
          this.fClass = 'down'
        }
        const timer = setTimeout(() => {
          this.fClass = ''
          clearTimeout(timer)
        }, 3000)
      }
    }
  },
  methods: {
    top () {
      this.$emit('toTop', this.info)
      if (!this.localTopIds.includes(this.info.tournament_id)) {
        this.localTopIds.push(this.info.tournament_id)
      } else {
        const idx = this.localTopIds.indexOf(this.info.tournament_id)
        this.localTopIds.splice(idx, 1)
      }
    },
    collect () {
      this.$emit('collect', this.info)
    },
    getAnchor () {
      this.$emit('getAnchor', this.info)
    }
  },
  mounted () {
    this.localTopIds = JSON.parse(localStorage.getItem('basketball_top_ids')) || []
  }
}
</script>

<style lang="less" scoped>
.basketball-item-wrap {
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.08);
  border-radius: 10px;
  margin-bottom: 10px;
  .item-title {
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 10px;
    text-align: center;
    color: rgba(0,0,0,.2);
    font-size: 14px;
    .time {
      width: 92px;
      color: #000;
    }
    .statues {
      width: 140px;

      &.going {
        color: #FF5858;
      }
      &.end {
        color: #FFB0B0;
      }
    }
    .score {
      display: flex;
      width: 180px;
      div {
        flex: 1;
      }
      .ot {
        color: #000;
      }
    }
    .half-score {
      width: 54px;
    }
    .all-score {
      width: 72px;
    }
    .diff-score {
      width: 110px;
    }
    .total-score {
      width: 110px;
    }
    .success {
      width: 88px;
    }
    .odd {
      width: 139px;
    }
    .all-odd {
      width: 139px;
    }
    .play {
      flex: 1;
    }
  }
  .item-body {
    display: flex;
    height: 92px;
    overflow: hidden;
    color: #000;
    font-size: 14px;
    justify-content: space-between;
    text-align: center;
    padding: 0 10px;
    .league {
      width: 92px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .statues {
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          color:#000000;
        }
      }
    }
    .score {
      display: flex;
      width: 180px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        > div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ot {
        color: #000;
      }
    }
    .half-score {
      width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .all-score {
      width: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .diff-score {
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .total-score {
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .success {
      width: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #f9f9f9;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .odd {
      width: 139px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #f9f9f9;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 20px;
        > span {
          flex: 1;
          text-align: center;
          &.active {
            color: #FF8F31;
          }
        }
      }
    }
    .all-odd {
      width: 139px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #f9f9f9;
      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 20px;
        > span {
          flex: 1;
          text-align: center;
          &.active {
            color: #FF8F31;
          }
        }
      }
    }
    .play {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        span {
          width: 20px;
          text-align: center;
        }
      }
      img {
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .item-remark {
    background: #F0F0F0;
    color:#FF6B00;
    padding: 5px 10px;
    text-align:center;
  }

}
</style>