import { render, staticRenderFns } from "./footballScheduleItem.vue?vue&type=template&id=49306a6e&scoped=true"
import script from "./footballScheduleItem.vue?vue&type=script&lang=js"
export * from "./footballScheduleItem.vue?vue&type=script&lang=js"
import style0 from "./footballScheduleItem.vue?vue&type=style&index=0&id=49306a6e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49306a6e",
  null
  
)

export default component.exports