<template>
  <div class="football-list-wrap">
    <!-- 完赛赛程 -->
    <div class="foot-ball-title completed" v-if="routerName === 'completed'">
      <div class="league">{{$t('league')}}</div>
      <div class="time">{{$t('time')}}</div>
      <div class="statues">{{$t('state')}}</div>
      <div class="home">{{$t('homeTeam')}}</div>
      <div class="score">{{$t('score')}}</div>
      <div class="away">{{$t('awayTeam')}}</div>
      <div class="half">{{$t('half')}}</div>
      <div class="corner">{{$t('cornerKick')}}</div>
      <div class="success">{{$t('victoryOrDefeat')}}</div>
      <div class="handicap">{{$t('handicap')}}</div>
      <div class="goals">{{$t('goalNumber')}}</div>
      <div class="data">{{$t('data')}}</div>
    </div>
    <!-- 未来赛程 -->
    <div class="foot-ball-title schedule" v-else-if="routerName === 'schedule'">
      <div class="league">{{$t('league')}}</div>
      <div class="time">{{$t('time')}}</div>
      <div class="statues">{{$t('state')}}</div>
      <div class="home">{{$t('homeTeam')}}</div>
      <div class="score">{{$t('score')}}</div>
      <div class="away">{{$t('awayTeam')}}</div>
      <div class="video">{{$t('live')}}</div>
      <div class="odd">{{$t('handicap')}}</div>
      <div class="live">{{$t('descant')}}</div>
      <div class="data">{{$t('data')}}</div>
      <div class="play">{{$t('operate')}}</div>
    </div>
    <div class="foot-ball-title live" v-else>
      <div class="league">{{$t('league')}}</div>
      <div class="time">{{$t('time')}}</div>
      <div class="statues">{{$t('state')}}</div>
      <div class="home">{{$t('homeTeam')}}</div>
      <div class="score">{{$t('score')}}</div>
      <div class="away">{{$t('awayTeam')}}</div>
      <div class="half">{{$t('half')}}</div>
      <div class="corner">{{$t('cornerKick')}}</div>
      <div class="video">{{$t('live')}}</div>
      <div class="odd">{{$t('odds')}}</div>
      <div class="data">{{$t('data')}}</div>
      <div class="live">{{$t('descant')}}</div>
      <div class="play">{{$t('operate')}}</div>
    </div>
    <div class="football-list-wrap">
      <template v-if="routerName === 'collect'">
        <!-- 收藏赛事 -->
        <template v-if="userCollectList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in userCollectList">
              <footballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds"
                @getAnchor="getAnchor" @toTop="toTop" @collect="collect" />
            </template>
          </div>
        </template>
      </template>
      <template v-else-if="routerName === 'completed'">
        <!-- 完赛赛事 -->
        <template v-if="completedList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in completedList">
              <footballCompletedItem :info="item" :key="`start-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect" />
            </template>
          </div>
        </template>
      </template>
      <template v-else-if="routerName === 'schedule'">
        <!-- 未来赛事 -->
        <template v-if="scheduleList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in scheduleList">
              <footballScheduleItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds"
                @getAnchor="getAnchor" @toTop="toTop" @collect="collect" />
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <!-- 置顶赛事 -->
        <template v-if="topList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in topList">
              <footballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds"
                @getAnchor="getAnchor" @toTop="toTop" @collect="collect" />
            </template>
          </div>
        </template>
        <!-- 进行中赛事 -->
        <template v-if="onStartList.length > 0">
          <div class="on-start statue-title">{{$t('inProgress')}}</div>
          <div class="football-list">
            <template v-for="(item, idx) in onStartList">
              <footballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds"
                @getAnchor="getAnchor" @toTop="toTop" @collect="collect" />
            </template>
          </div>
        </template>
        <template v-if="onWaitList.length > 0">
          <div class="on-wait statue-title">{{$t('haNotStarted')}}</div>
          <div class="football-list">
            <template v-for="(item, idx) in onWaitList">
              <footballItem :info="item" :key="`wait-${item.tournament_id}-${idx}`" :collectIds="localCollectIds"
                @getAnchor="getAnchor" @toTop="toTop" @collect="collect" />
            </template>
          </div>
        </template>
        <template v-if="onEndList.length > 0">
          <div class="on-end statue-title">{{$t('over')}}</div>
          <div class="football-list">
            <template v-for="(item, idx) in onEndList">
              <footballItem :info="item" :key="`end-${item.tournament_id}-${idx}`" :collectIds="localCollectIds"
                @getAnchor="getAnchor" @toTop="toTop" @collect="collect" />
            </template>
          </div>
        </template>
      </template>
    </div>
    <div class="football-img-list">
      <p class="tips">
        <img src="@/assets/images/score/tips.png" alt="" />
        <span>{{$t('illustration')}}：</span>
      </p>
      <div class="img-tip">
        <div class="tip-item">
          <img src="@/assets/images/score/goal.png" alt="" />
          <span>{{ $t('goal') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/penalty.png" alt="" />
          <span>{{ $t('penaltyKick') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/own-goal.png" alt="" />
          <span>{{ $t('Oolong') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/red-card.png" alt="" />
          <span>{{ $t('redCard') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/yellow-card.png" alt="" />
          <span>{{ $t('yellowCard') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/yellow-red.png" alt="" />
          <span>{{ $t('twoYellowsTurnoOneRed') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/exchange.png" alt="" />
          <span> {{ $t('substitution') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/corner.png" alt="" />
          <span>{{ $t('cornerKick') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/live.png" alt="" />
          <span>{{ $t('animation') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/video.png" alt="" />
          <span>{{ $t('live') }}</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/images/score/voice.png" alt="" />
          <span>{{ $t('descant') }}</span>
        </div>
      </div>
    </div>
    <a-drawer :width="404" :visible="drawer" :direction="direction" :modal="false" @close="handleClose">
      <template slot="title">
        <div class="drawer-title">{{ $t('descant') }}</div>
      </template>
      <div class="drawer-body">
        <ul>
          <li class="anchor-item" v-for="(i, idx) in anchors" :key="`${idx}-${i.member_id}`">
            <a :href="`/detail/1/${i.tournament_id}/${i.member_id}`" target="_blank">
              <div class="anchor-img">
                <img :src="i.face" alt="" />
              </div>
              <p class="anchor-name">{{ i.username }}{{ i.type }}</p>
            </a>
          </li>
        </ul>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import dayjs from "dayjs";
import footballCompletedItem from "@/components/score/footballCompletedItem.vue";
import footballScheduleItem from "@/components/score/footballScheduleItem.vue";
import footballItem from "@/components/score/footballItem.vue";
import { websocketCommand } from "../../utils/websocket.js";
import storage from "@/utils/storage";
import { mapActions, mapState } from "vuex";
export default {
  name: "football-score",
  components: {
    footballItem,
    footballCompletedItem,
    footballScheduleItem,
  },
  props: {
    filterLeagues: Array,
  },
  data() {
    return {
      list: [],
      localTopIds: [],
      userCollectList: [],
      ftTimeWs: null,
      ftLiveWs: null,
      drawer: false,
      direction: "rtl",
      anchors: [],
      lock: false,
    };
  },
  computed: {
    ...mapState({
      scoreLanguage: "scoreLanguage",
    }),
    routerName() {
      return this.$route.name;
    },
    localCollectIds() {
      return (
        Array.from(
          this.userCollectList,
          ({ tournament_id }) => tournament_id
        ) || []
      );
    },
    completedList() {
      return (
        this.list.filter(
          (i) => i.state == -1 && this.filterLeagues.includes(i.league_id)
        ) || []
      );
    },

    scheduleList() {
      return (
        this.list.filter(
          (i) => i.state == 0 && this.filterLeagues.includes(i.league_id)
        ) || []
      );
    },
    filterList() {
      return (
        this.list.filter(
          (i) =>
            this.localTopIds.indexOf(i.tournament_id) < 0 &&
            this.filterLeagues.includes(i.league_id)
        ) || []
      );
    },
    topList() {
      return (
        this.list.filter((i) => this.localTopIds.includes(i.tournament_id)) ||
        []
      );
    },
    onStartList() {
      return this.filterList.filter((i) => i.state >= 1 && i.state <= 5) || [];
    },
    onWaitList() {
      return this.filterList.filter((i) => i.state == 0) || [];
    },
    onEndList() {
      return this.filterList.filter((i) => i.state < 0) || [];
    },
  },
  methods: {
    getAnchor(info) {
      this.$api
        .get(
          `/get_public_anchor?tournament_id=${info.tournament_id}&type=1`
        )
        .then((res) => {
          this.drawer = true;
          this.anchors = (res.data || {}).more || [];
        });
    },
    handleClose() {
      this.drawer = false;
      this.anchors = [];
    },
    getList() {
      const today = dayjs().format("YYYY-MM-DD");
      let day = this.$route.query.day
        ? dayjs(this.$route.query.day).format("YYYY-MM-DD")
        : today;

      if (["completed", "schedule"].includes(this.$route.name)) {
        day = day || dayjs(new Date()).format("YYYY-MM-DD");
      }
      let url = `/web/football_score_list?day=${day}`;
      if (["completed"].includes(this.$route.name)) {
        url = `${url}&state=-1`;
      }
      if (["schedule"].includes(this.$route.name)) {
        url = `${url}&state=0`;
      }

      this.$api.get(url).then((res) => {
        this.list = res.data || [];
      });
    },
    getCollectList() {
      this.$api.get(`/web/get_match_follow_list?genre=1`).then((res) => {
        if (res.code === 200) {
          this.userCollectList = res.data || [];
        }
      });
    },
    toTop(info) {
      if (!this.localTopIds.includes(info.tournament_id)) {
        this.localTopIds.push(info.tournament_id);
      } else {
        const idx = this.localTopIds.indexOf(info.tournament_id);
        this.localTopIds.splice(idx, 1);
      }

      localStorage.setItem(
        "football_top_ids",
        JSON.stringify(this.localTopIds)
      );
    },
    collect(info) {

      var token = storage.get("token");
      if (!token) {
        this.$parent.$refs.header.onOpen();
        return false;
      } else {
        if (this.lock) {
          return false
        }
        this.lock = true
        let follow = 1;
        if (!this.localCollectIds.includes(info.tournament_id)) {
          follow = 1;
        } else {
          follow = 0;
        }
        this.$api
          .post(`/web/set_match_follow`, {
            tournament_id: info.tournament_id,
            genre: 1,
            is_follow: follow,
          })
          .then((res) => {
            if (res.code === 200) {
              if (follow == 1) {
                this.userCollectList.push(info);
              } else {
                const idx = this.userCollectList.findIndex(
                  ({ tournament_id }) => info.tournament_id === tournament_id
                );
                this.userCollectList.splice(idx, 1);
              }
              this.lock = false
              
            }     
          });
      }
    },
    // 建立连接、发起ws请求，以心跳方式，向服务端发送数据
    createWs() {
      this.ftTimeWs = new WebSocket(this.wsUrl);
      // this.ftLiveWs = new WebSocket(this.wsUrl);
      // 若为对象类型，以回调方式发送
      websocketCommand(
        this.ftTimeWs,
        "create",
        JSON.stringify({"event":"woker:subscribe","data":{"channel":"bifen-football"}}),
        this.receiveMsg
      );
      // websocketCommand(
      //   this.ftLiveWs,
      //   "create",
      //   JSON.stringify({ type: "init", task_id: "footballlive" }),
      //   this.receiveMsg
      // );
    },
    receiveMsg(msg) {
      console.log(msg.data);
      // 比赛时间更新
      if (msg.channel === "bifen-football" ) {
        const oList = JSON.parse(JSON.stringify(this.list));
        const nList = oList.map((item) => {
          const game =
            msg.data.filter(
              (wsGame) => wsGame.tournament_id == item.tournament_id
            )[0] || {};
          const newGame = Object.assign({}, item, game);
          return newGame;
        });
        nList.forEach(item => {
          item.tournament_id = Number(item.tournament_id)
        })
        this.list = nList;
      }
      // 指数推送 处理 task_id: football_list 的指数推送，footballlive 的不管
      // if (msg.type === "odds" && !msg.result.category) {
      //   const oList = JSON.parse(JSON.stringify(this.list));
      //   const nList = oList.map((item) => {
      //     const game =
      //       msg.result.filter(
      //         (wsGame) => wsGame.tournament_id == item.tournament_id
      //       )[0] || {};
      //     const newGame = Object.assign({}, item, game);
      //     return newGame;
      //   });
      //   this.list = nList;
      // }
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.query) {
        this.list = []
        this.getList();
        var token = storage.get("token");
        if (token) {
          this.getCollectList();
        }
      }
    },
  },
  mounted() {
    console.log('从新');
    this.localTopIds =
      JSON.parse(localStorage.getItem("football_top_ids")) || [];

    this.getList();
    this.createWs();
    var token = storage.get("token");
    if (token) {
      this.getCollectList();
    }
  },
  beforeDestroy() {
    websocketCommand(this.ftTimeWs, "close");
    websocketCommand(this.ftLiveWs, "close");
    this.ftTimeWs = null;
    this.ftLiveWs = null;
  },
};
</script>

<style lang="less" scoped>
@import url('~@/assets/less/variables.less');

.football-list-wrap {
  margin-top: 16px;

  .foot-ball-title {
    display: flex;
    height: 54px;
    line-height: 54px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    justify-content: space-between;
    text-align: center;

    &.live {
      .league {
        width: 92px;
      }

      .time {
        width: 80px;
      }

      .statues {
        width: 80px;
      }

      .home {
        width: 148px;
      }

      .score {
        width: 52px;
      }

      .away {
        width: 148px;
      }

      .half {
        width: 50px;
      }

      .corner {
        width: 82px;
      }

      .video {
        width: 50px;
      }

      .odd {
        flex: 1;
      }

      .data {
        width: 50px;
      }

      .live {
        width: 50px;
      }

      .play {
        width: 90px;
      }
    }

    &.schedule {
      .league {
        width: 92px;
      }

      .time {
        width: 92px;
      }

      .statues {
        width: 92px;
      }

      .home {
        width: 148px;
      }

      .score {
        width: 52px;
      }

      .away {
        width: 148px;
      }

      .video {
        width: 80px;
      }

      .odd {
        width: 80px;
      }

      .data {
        width: 80px;
      }

      .live {
        width: 80px;
      }

      .play {
        width: 80px;
      }
    }

    &.completed {
      .league {
        width: 92px;
      }

      .time {
        width: 92px;
      }

      .statues {
        width: 92px;
      }

      .home {
        width: 148px;
      }

      .score {
        width: 52px;
      }

      .away {
        width: 148px;
      }

      .half {
        width: 82px;
      }

      .corner {
        width: 82px;
      }

      .success {
        width: 100px;
      }

      .handicap {
        width: 100px;
      }

      .goals {
        width: 100px;
      }

      .data {
        width: 100px;
      }
    }
  }

  .football-list-wrap {
    .statue-title {
      height: 54px;
      line-height: 54px;
      position: relative;
      padding-left: 20px;
      font-size: 16px;
      color: #000;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 16px;
        height: 22px;
        width: 6px;
        border-radius: 4px;
      }

      &.on-start {
        &::after {
          background: #28c927;
        }
      }

      &.on-wait {
        &::after {
          background: #ffcd00;
        }
      }

      &.on-end {
        &::after {
          background: #cccccc;
        }
      }
    }

    .football-list {
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .football-img-list {
    display: flex;
    align-items: center;
    margin-top: 36px;
    gap: 10px;

    .tips {
      display: flex;
      align-items: center;
      font-size: 16px;
      width:200px;
      color: rgba(0, 0, 0, 0.6);
      margin:0;
      img {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .img-tip {
      display: flex;
      flex-wrap: wrap;
      .tip-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #000;
        margin: 0 40px 10px 0;

        img {
          display: block;
          height: 17px;
          margin-right: 4px;
        }
      }
    }
  }

}

.drawer-title {
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

.drawer-body {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;

    li {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      .anchor-img {
        width: 46px;
        height: 46px;
        padding: 2px;
        border: 1px solid #ffcd00;
        border-radius: 50%;
        position: relative;

        &::after {
          position: absolute;
          bottom: -8px;
          content: "直播中";
          font-size: 12px;
          width: 48px;
          height: 18px;
          font-size: 12px;
          border-radius: 18px;
          background: #ffcd00;
          text-align: center;
          line-height: 18px;
          left: -1px;
          transform: scale(0.7);
        }

        a {
          display: block;
        }

        img {
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      .anchor-name {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 20px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: #000000;
      }
    }
  }
}
</style>
